import React from 'react';
import "../css/tryView.css";
import { Info } from 'lucide-react';
import { IoLogoGithub } from "react-icons/io";
import { Link } from 'react-router-dom';

const Try = () => {
    return (
        <div className="try-page-container">
            <div className="disclaimer">
                <Info size={18} strokeWidth={2} />
                <p>This page is not a part of the template</p>
            </div>
            <div className='try-box-container'>
                <div className="try-box">
                    <div className='try-box-text'>
                        <h2>Try Now</h2>
                        <h3>Get the code and start building your portfolio</h3>
                    </div>
                    <div className='actions-div'>
                        <button className="primary-button join-button" onClick={
                            () => { window.open('https://github.com/satwikbhasin/virtual-vault-portfolio-builder', '_blank') }
                        }>
                            <IoLogoGithub style={{
                                marginRight: '5px',
                                fontSize: '20px'
                            }} />
                            GitHub
                        </button>
                    </div>
                </div>
                <div className="try-box">
                    <div className='try-box-text'>
                        <h2>Not a Developer?</h2>
                        <h3>Get this website tailored to your needs</h3>
                    </div>
                    <div className='actions-div'>
                        <Link to="/contact" className="primary-button join-button">Let's Talk</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Try;